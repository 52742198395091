<template>
  <v-container fluid class="d-flex justify-center" v-if="!isLoading">
    <div class="d-flex flex-column" style="min-width: 80%">
      <v-alert dense border="left" outlined type="warning" v-if="!is_logged_in">
        You must be <strong>logged in</strong> to join our discord.
      </v-alert>
      Congratulations! You should be in the discord server now.
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";
import { LOGIN, JOIN_DISCORD } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

export default Vue.extend({
  computed: {
    ...mapGetters(["isLoading", "is_logged_in"]),
  },
  async created() {
    if (!this.$route.query.code)
      return this.$router.push({ name: "Internal Error" });

    store.commit(BEGIN_LOADING);
    if (!this.is_logged_in && this.$cookies.isKey("user_profile")) {
      await store.dispatch(LOGIN);
    }

    await store.dispatch(JOIN_DISCORD, this.$route.query.code);
    store.commit(END_LOADING);
  },
});
</script>
